export function initDropdown() {
    let dropdownButton = document.querySelector('.toggle-dropdown');

    if (dropdownButton) {
        dropdownButton.addEventListener('click', (event) => {
            document.querySelector('.common-dropdown').classList.toggle('active');
        });
    }
}

export function initOverlay() {
    let overlayButton = document.querySelector('.toggle-overlay');

    if (overlayButton) {
        overlayButton.addEventListener('click', (event) => {
            document.querySelector('.notification-overlay').classList.toggle('active');
        });
    }
}

export function initModal() {
    let showModalButton = document.querySelector('.show-modal');
    let modal = document.querySelector('.modal-overlay');
    let closeModal = document.querySelector('.close-modal');

    if (showModalButton) {
        showModalButton.addEventListener('click', (event) => {
            modal.classList.toggle('active');
        });
    }

    if (closeModal) {
        closeModal.addEventListener('click', (event) => {
            modal.classList.toggle('active');
        });
    }

    if (modal) {
        modal.addEventListener('click', (event) => {
            event.preventDefault();
            let modalFirstChild = event.target.className;

            if (modalFirstChild === 'modal-wrapper') {
                modal.classList.toggle('active');
            }
        })
    }


}