import { initMobile } from './modules/mobile'
import { initDropdown } from './modules/togglers'
import { initOverlay } from './modules/togglers'
import { initModal } from './modules/togglers'

document.addEventListener('DOMContentLoaded', function() {
	initMobile();
	initDropdown();
	initOverlay();
    // initModal();
});

jQuery(document).ready(function( $ ) {
	// Datepicker
	$(function() {
		$("#datepicker").datepicker({
			inline:true,
			showOtherMonths: true,
			altField: "#date-start-value",
			altFormat: "dd M yy",
			dateFormat: "dd M yy",
			onSelect: function(dateText){
				$('#date-start-output').html(dateText);
			}
		});
	});

	$(function() {
		$(".datepicker").datepicker({
			inline:true,
			showOtherMonths: true,
			altField: "#date-start-value",
			altFormat: "dd M yy",
			dateFormat: "dd M yy",
			onSelect: function(dateText){
				$('#date-start-output').html(dateText);
			}
		});
	});

	// Side submenus
	$('.has-submenu > a').click(function(e) {
		e.preventDefault();
		$(this).parent().toggleClass('submenu-level2-parent');
		$(this).parent().find('.submenu-level2').slideToggle(800);
	});

	// Big upload btn
	$( ".big-upload-input" ).not(".multiple").change(function( event ) {
		var path = $(this).get(0).value;
		var filename = path.replace(/^.*\\/, "");
		$(this).parent().find(".init").hide();
		$(this).parent().find(".path").show();
		$(this).parent().find(".path").html(filename + ' <span class="id-font4">m</span>');
		$(this).parent().find(".bin").show();
		$(this).parent().find(".photo").show();
		$('.add-model .step3 .button-main').removeClass('disabled');
		$('.add-model .step4 .button-main').removeClass('disabled');
	});
	$( ".big-upload-btn .bin").click(function() {
		$(this).parent().parent().find(".init").show();
		$(this).parent().parent().find(".path").hide();
		$(this).parent().parent().find(".bin").hide();
	});
	$( ".big-upload-input.multiple" ).change(function( event ) {
		if( !$('body').hasClass('create-certificat') ) {
			if($(this).hasClass('model-list')) {
				$('.add-model .step4 .upload-wrapper2 .uploaded-medias-list').show();
				$('.add-model .step4 .reopen-link.open-choices2').show();
			} else {
				var numFiles = $(this).get(0).files.length;
		   		console.log(numFiles);
		   		var that = $(this);
		   		//$('.big-upload-input').hide();
		   		$('.add-media .button-main').removeClass('disabled');
		   		if($('.stepfile .button-main').hasClass('ready')) {
		   			$('.stepfile .button-main').removeClass('disabled');
		   		}
		   		$('.stepfile .button-main').addClass('ready');
		   		$('.add-modal .stepfile .button-main').addClass('ready');
				that.parent().find(".path").addClass('not-underlined');
				var limit = 3;
				if($('.modal-wrapper').hasClass('add-model')) {
					limit = 2;
				}
		   		if(numFiles > limit) {
					var filename = '<p class="title">10 médias sélectionnés</p>';
					filename += '<p class="edit">Modifier la sélection</p>';
					that.parent().find(".init").hide();
					that.parent().find(".path").show();
					that.parent().find(".path").html(filename);
					that.parent().find(".bin").show();
					that.parent().find(".photo").show();
					////$('.upload-wrapper').addClass('selected');
					that.parent().find(".path").css({'overflow':'initial'});
				} else {
					that.parent().find(".init").hide();
					var filename = '<p class="title">2 médias sélectionnés</p>';
					filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
					filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
					//filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
					that.parent().find(".path").html(filename);
					////$('.upload-wrapper').removeClass('selected');
					that.parent().find(".path").css({'overflow':'initial'});
				}
			}
		}
	});
	$( ".upload-wrapper" ).click(function( event ) {
		if($(this).hasClass('selected')) {
			var filename = '<p class="title">10 médias sélectionnés</p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			$(this).find(".path").html(filename);
			$(this).find(".path").css({'overflow':'scroll'});
		}
	});
	/*$( ".big-upload-input.multiple .file .id-font4" ).click(function() {
		$(this).parent().addClass('test');
	});*/

	// Lang switcher
	$('.select-lang').click(function() {
		$(this).find('ul').slideToggle(200);
		$(this).find('.lang-selected').toggleClass('on');
	});

	// Tabs switch
	$('.switch-tabs a').click(function(e) {
		e.preventDefault();
		var target = $(this).attr('href');
		$('.request-cards-wrapper').hide();
		$(target).show();
		$(this).closest('.tabs').find('li').removeClass('current');
		$(this).closest('li').addClass('current');
	});

	// Sorting
	$('.sort-by').click(function() {
		$(this).find('ul').slideToggle(200);
		$(this).toggleClass('on');
	});

	// Scrollbar
	$('.scrollbar-inner').scrollbar();

	// Select menu
	var x, i, j, selElmnt, a, b, c;
	/* Look for any elements with the class "custom-select": */
	x = document.getElementsByClassName("custom-select");
	for (i = 0; i < x.length; i++) {
		selElmnt = x[i].getElementsByTagName("select")[0];
		/* For each element, create a new DIV that will act as the selected item: */
		a = document.createElement("DIV");
		a.setAttribute("class", "select-selected");
		a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
		x[i].appendChild(a);
		/* For each element, create a new DIV that will contain the option list: */
		b = document.createElement("DIV");
		b.setAttribute("class", "select-items select-hide");
		for (j = 1; j < selElmnt.length; j++) {
	    /* For each option in the original select element,
	    create a new DIV that will act as an option item: */
	    c = document.createElement("DIV");
	    c.innerHTML = selElmnt.options[j].innerHTML;
	    //c.classList.add("item");
	    c.addEventListener("click", function(e) {
	        /* When an item is clicked, update the original select box,
	        and the selected item: */
	        var y, i, k, s, h;
	        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
	        h = this.parentNode.previousSibling;
	        for (i = 0; i < s.length; i++) {
	        	if (s.options[i].innerHTML == this.innerHTML) {
	        		s.selectedIndex = i;
	        		h.innerHTML = this.innerHTML;
	        		y = this.parentNode.getElementsByClassName("same-as-selected");
	        		for (k = 0; k < y.length; k++) {
	        			y[k].removeAttribute("class");
	        		}
	        		this.setAttribute("class", "same-as-selected");
	        		break;
	        	}
	        }
	        h.click();
	    });
	    b.appendChild(c);
	}
	x[i].appendChild(b);
	a.addEventListener("click", function(e) {
	    /* When the select box is clicked, close any other select boxes,
	    and open/close the current select box: */
	    e.stopPropagation();
	    closeAllSelect(this);
	    this.nextSibling.classList.toggle("select-hide");
	    this.classList.toggle("select-arrow-active");
	});
}
$('.select-selected').click(function() {
	$(this).parent().toggleClass('clicked');
});
$('body').click(function() {
	if($('.custom-select:hover').length == 0) {
		$('.custom-select').removeClass('clicked');
	}
});

function closeAllSelect(elmnt) {
	  /* A function that will close all select boxes in the document,
	  except the current select box: */
	  var x, y, i, arrNo = [];
	  x = document.getElementsByClassName("select-items");
	  y = document.getElementsByClassName("select-selected");
	  for (i = 0; i < y.length; i++) {
	  	if (elmnt == y[i]) {
	  		arrNo.push(i)
	  	} else {
	  		y[i].classList.remove("select-arrow-active");
	  	}
	  }
	  for (i = 0; i < x.length; i++) {
	  	if (arrNo.indexOf(i)) {
	  		x[i].classList.add("select-hide");
	  	}
	  }
	}
	/* If the user clicks anywhere outside the select box,
	then close all select boxes: */
	document.addEventListener("click", closeAllSelect);


	//// Pour la démo
	$('.goto-step1').click(function(e) {
		e.preventDefault();
		$('#library-step2').fadeOut(100);
		setTimeout(function() {
			$('#library-step1').fadeIn(100);
		}, 150);
	});
	$('.goto-step2').click(function(e) {
		e.preventDefault();
		$('#library-step1').fadeOut(100);
		$('#library-step3').fadeOut(100);
		setTimeout(function() {
			$('#library-step2').fadeIn(100);
		}, 150);
	});
	$('.form-wrapper .half-block-container').click(function() {
		$('.form-wrapper .half-block-container').toggleClass('campaignKind');
	});
	$('.goto-step3').click(function(e) {
		e.preventDefault();
		$('#library-step2').fadeOut(100);
		$('#library-step4').fadeOut(100);
		setTimeout(function() {
			$('#library-step3').fadeIn(100);
		}, 150);
	});
	$('.goto-step4').click(function(e) {
		e.preventDefault();
		$('#library-step3').fadeOut(100);
		setTimeout(function() {
			$('#library-step4').fadeIn(100);
		}, 150);
	});

	// Transfert Certificat : Pour la démo
	$('#transfert-modal .close').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-acceptation-done').hide();
		$('.step-rejection-done').hide();
		$('.step-init').fadeIn();
		$('#transfert-modal').removeClass('on');
		$('.side-modal').removeClass('on');
	});
	$('.sticky-buttons .accept').click(function() {
		$('.step-init').hide();
		$('.step-accept').fadeIn();
	});
	$('.sticky-buttons .reject').click(function() {
		$('.step-init').hide();
		$('.step-reject').fadeIn();
	});
	$('.sticky-buttons .goto-init').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-init').fadeIn();
		$('.side-modal').removeClass('on');
		$('#shadow').fadeOut();
	});
	$('.sticky-buttons .confirm-acceptation').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-acceptation-done').fadeIn();
	});
	$('.sticky-buttons .confirm-rejection').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-rejection-done').fadeIn();
	});

	$('.insurance-card .block-content').click(function() {
		$('.side-modal').addClass('on');
		$('#insurance-details-modal .sticky-buttons').fadeIn();
		$('#shadow').fadeIn();
		$('body').css({'overflow':'hidden'});
	});
	$('#insurance-details-modal .goto-init').click(function() {
		$('.side-modal').removeClass('on');
		$('#shadow').fadeOut();
		$('body').css({'overflow':'initial'});
	});
	$('#shadow').click(function() {
		$('.side-modal').removeClass('on');
		$('#shadow').fadeOut();
		$('#transfer-certificat .sticky-buttons').fadeOut();
		$('body').css({'overflow':'initial'});
	});

	// Transfert details : Pour la démo
	$('.transfert-card.open-modal').click(function() {
		$('.transfert-detail-modal').addClass('on');
	});
	$('.inner-slide').click(function() {
		$(this).parent().find('.table-wrapper').slideToggle();
		$(this).toggleClass('on');
	});

	// Emmission de nouveaux certificats
	$('.summernote').summernote();

	resizeUploadWrapper();
	$(window).on('resize', function () {
		resizeUploadWrapper();
	});
	function resizeUploadWrapper() {
		$('.new-certificats-forms .upload-img').each(function() {
			var width = $(this).width();
			$(this).height(width);
		});
	}

	// Slick
	$('.my-certificat-wrapper .certificat-slider').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: $('.button-prev'),
		nextArrow: $('.button-next'),
		responsive: [
		{
			breakpoint: 1190,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
			}
		}
		]
	});

	// Certificat - Tabs
	$('.certificat-tabs li').click(function () {
		var classTarget = $(this).attr('data-target');
		$('.certificat-tabs li').removeClass('active');
		$(this).addClass('active');
		$('.tab-target').hide();
		$('.'+classTarget).show();
	});

	$('.historique-button').click(function() {
		$('.historique').toggleClass('on');
		if($('.historique').hasClass('on')) {
			$('body').css({'overflow':'hidden'});
		} else {
			$('body').css({'overflow':'initial'});
		}
		$('.auth-block-top-bar .certificate-id').addClass('blur');
		$('.auth-block-top-bar .bubbles').addClass('blur');
		$('.center-wrapper .title').addClass('blur');
		$('.tabs').addClass('blur');
		$('.specifications').addClass('blur');
		$('.description').addClass('blur');
		$('.images').addClass('blur');
		$('.documents').addClass('blur');
		$('.btns-wrapper').addClass('blur');
	});
	$('.certificat-mob .close').click(function() {
		$('body').css({'overflow':'initial'});
		$('.historique').removeClass('on');
		$('.auth-block-top-bar .certificate-id').removeClass('blur');
		$('.auth-block-top-bar .bubbles').removeClass('blur');
		$('.center-wrapper .title').removeClass('blur');
		$('.tabs').removeClass('blur');
		$('.specifications').removeClass('blur');
		$('.description').removeClass('blur');
		$('.images').removeClass('blur');
		$('.documents').removeClass('blur');
		$('.btns-wrapper').removeClass('blur');

	});

	// Certificat - Modal
	$(".modal-link").click(function(e) {
		e.preventDefault();
		$('.modal').fadeIn(300);
		$('body').css({'overflow':'hidden'});
	});
	$('.open-add-certificat-modal').click(function(e) {
		e.preventDefault();
		$('.add-certificat-overlay').fadeIn();
	});
	$('.add-certificat .close').click(function(e) {
		e.preventDefault();
		$('#shadow').fadeOut();
		$('.add-certificat-overlay').fadeOut();
		$('body').css({'overflow':'hidden'});

	});
	$('.add-certificat .close-like').click(function(e) {
		e.preventDefault();
		$('#shadow').fadeOut();
		$('.add-certificat-overlay').fadeOut();
		$('body').css({'overflow':'hidden'});

	});
	$(".close-modal").click(function(e) {
		e.preventDefault();
		$('.modal').fadeOut(300);
		$('.side-modal').removeClass('on');
		$('body').css({'overflow':'initial'});
		$('.shadow-wrapper').fadeOut();
		$('#shadow').fadeOut();
		$('#transfer-certificat .sticky-buttons').fadeOut();
	});

	$('.modal-goto-step0').click(function(e) {
		e.preventDefault();
		$('.modal').fadeOut(300);
		$('.side-modal').removeClass('on');
		$('body').css({'overflow':'initial'});
		$('.shadow-wrapper').fadeOut();
		$('#shadow').fadeOut();
		$('#transfer-certificat .sticky-buttons').fadeOut();
	});

	// Burger
	$('.burger').click(function() {
		$('.side-part').addClass('on');
		$('.side-menu-container').addClass('on');
		$('body').addClass('hidden');
	});
	$('.side-menu-container .close').click(function() {
		$('.side-part').removeClass('on');
		$('.side-menu-container').removeClass('on');
		$('body').removeClass('hidden');
	});

	// Wallet
	$('.cards-wrapper .title-wrapper').click(function() {
		$(this).next('.cards').slideToggle();
		$(this).find('.arrow').toggleClass('closed');
	});

	// Case serial
	if($(window).width() > 665) {
		$('.case-serial-info').mouseover(function() {
			$('.case-serial-hover').addClass('on');
		});
		$('.case-serial-info').mouseout(function() {
			$('.case-serial-hover').removeClass('on');
		});
	} else {
		$('.case-serial-info').click(function() {
			$('body').css({'overflow':'hidden'});
			$('.case-serial-hover').addClass('on');
		});
		$('.case-serial-hover .close').click(function() {
			$('body').css({'overflow':'auto'});
			$('.case-serial-hover').removeClass('on');
		});
	}

	// Case serial

	$('.info-popin-info').click(function() {
		var target = '.'+$(this).attr('data-target');
		$('body').css({'overflow':'hidden'});
		$(target).addClass('on');
	});

	$('.info-popin-hover .close').click(function() {
		$('body').css({'overflow':'auto'});
		$('.info-popin-hover').removeClass('on');
	});

	// Certificat Popin
	$('.open-certificat-popin').click(function(e) {
		e.preventDefault();
		$('.certificat-popin').addClass('on');
	});
	$('.close-certificat-popin').click(function() {
		$('.certificat-popin').removeClass('on');
	});

	// Insurance
	$('#insurance-blocks').click(function() {
		$(this).toggleClass('on');
		$(".insurance-content-wrapper").find('.blocks-wrapper').slideToggle();
	});

	// Popin insurance
	$('#popin-go-step-2').click(function(e) {
		e.preventDefault();
		$('.step-1').hide();
		$('.step-2').show();
		//$('.popin-btns-step1').hide();
		//$('.popin-btns-step2').show();
	});
	$('#popin-go-step-1').click(function(e) {
		e.preventDefault();
		$('.step-1').show();
		$('.step-2').hide();
		//$('.popin-btns-step1').show();
		//$('.popin-btns-step2').hide();
	});

	// Async scroll
	$('.historique-button.async').hide();
	$(window).scroll(function () {
		var height = 2400;
		if (!$("#insurance-blocks").hasClass('on')) {
			height = 600;
		}
		if ($(window).scrollTop() > height) {
			$('.historique-button.async').fadeIn();
		} else {
			$('.historique-button.async').fadeOut();
		}
	});

    // Side menu insurance
    $('.open-validation').click(function() {
    	$('.shadow-wrapper').fadeIn();
    });

	// Side menu -  Certificats List
	$('.dropdown-display').click(function() {
		$(this).toggleClass("open");
	});
	$('.certificats-list-container tr label').click(function() {
		var that = $(this).closest("tr");

		if(that.hasClass('enable')) {

			if( $('.icon-circle:hover').length == 0
				&& $('.id-font4:hover').length == 0
				&& $('.tooltip-container:hover').length == 0
				&& $('.tooltip:hover').length == 0) {

				if( that.find('input[type=checkbox]').is(':checked') ) {
					that.removeClass('selected');
				} else {
					that.addClass('selected');
				}
			}

			setTimeout(function() {

			if($('#line3').is(':checked') || $('#line4').is(':checked')) {
				if(!$('#line3').is(':checked') || !$('#line4').is(':checked')) {
					$('.single-selected-options').addClass('on');
					$('.selected-options').addClass('on');
					$("#all").prop( "checked", false );
					var count = 1;
					$('#all + label').html("Sélectionné ("+count+")");
					$('.options .question').addClass('on');
				} else {
					$('.single-selected-options').removeClass('on');
					$('.selected-options').addClass('on');
					$("#all").prop( "checked", true );
					$('#all + label').html("Tout décocher ("+count+")");
					var count = 2;
					$('#all + label').html("Tout décocher ("+count+")");
					$('.options .question').removeClass('on');
				}
			} else {
				$('.single-selected-options').removeClass('on');
				$('.selected-options').removeClass('on');
				$('.options .question').removeClass('on');

				$('#all + label').html("Tout sélectionner");
				$("#all").prop( "checked", false );
			}

			});
		}
	});
	$('.certificats-list-container #all').click(function() {
		if( $(this).is(':checked') ) {
			$('.selected-options').addClass('on');
			$("#line3").prop( "checked", true );
			$("#line4").prop( "checked", true );
			$("#line3").closest('tr').addClass('selected');
			$("#line4").closest('tr').addClass('selected');
			$("#all").prop( "checked", true );
			var count = 2;
			$('#all + label').html("Tout décocher ("+count+")");
		} else {
			$('.selected-options').removeClass('on');
			$("#line3").prop( "checked", false );
			$("#line4").prop( "checked", false );
			$("#line3").closest('tr').removeClass('selected');
			$("#line4").closest('tr').removeClass('selected');
			$("#all").prop( "checked", false );
			$('#all + label').html("Tout sélectionner");
		}

		if($('#line3').is(':checked') || $('#line4').is(':checked')) {
			if(!$('#line3').is(':checked') || !$('#line4').is(':checked')) {
				$('.options .question').addClass('on');
			} else {
				$('.options .question').removeClass('on');
			}
		} else {
			$('.options .question').removeClass('on');
		}
	});
	$('.certificats-list-container .dropdown-options').click(function() {
		/*if( $('#certificats-transferes').is(':checked') ) {
			$(".table-wrapper table tr.disabled").show();
			$('.display-label').html("Afficher : Certificats transférés");
		} else {
			$(".table-wrapper table tr.disabled").hide();
		}
		if( $('#certificats-en-portefeuille').is(':checked') ) {
			$(".table-wrapper table tr.enable").show();
			$('.display-label').html("Afficher : Certificats en portefeuille");
		} else {
			$(".table-wrapper table tr.enable").hide();
		}
		if( $('#certificats-transferes').is(':checked') && $('#certificats-en-portefeuille').is(':checked')) {
			$('.display-label').html("Afficher : Tout");
		}
		if( !$('#certificats-transferes').is(':checked') && !$('#certificats-en-portefeuille').is(':checked')) {
			$('.display-label').html("Afficher : Aucun certificat");
		}*/
		if( $('#en-attente').is(':checked') ) {
			$(".table-wrapper table tr.en-attente").show();
		}
		if(! $('#en-attente').is(':checked') ) {
			$(".table-wrapper table tr.en-attente").hide();
		}

		if( $('#acceptes').is(':checked') ) {
			$(".table-wrapper table tr.accepte").show();
		}
		if(! $('#acceptes').is(':checked') ) {
			$(".table-wrapper table tr.accepte").hide();
		}

		if( $('#refuses').is(':checked') ) {
			$(".table-wrapper table tr.refuse").show();
		}
		if(! $('#refuses').is(':checked') ) {
			$(".table-wrapper table tr.refuse").hide();
		}

		if( $('#en-attente').is(':checked') && $('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Tout");
		} else if (!$('#en-attente').is(':checked') && $('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Acceptés / Réfusés");
		} else if ($('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : En attente / Réfusés");
		} else if ($('#en-attente').is(':checked') && $('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : En attente / Acceptés");
		} else if (!$('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Réfusés");
		} else if (!$('#en-attente').is(':checked') && $('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Acceptés");
		} else if ($('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : En attente");
		} else if (!$('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Aucun");
		}
	});
	$('body').click(function() {
		if($('.dropdown-options:hover').length < 1 && $('.dropdown-display:hover').length < 1) {
			$('.dropdown-display').removeClass('open');
		}
	});

	// Medias
	$('.medias-list-container tr label').click(function() {
		var that = $(this).closest("tr");
		$('.options .question').removeClass('on');

			if( $('.icon-circle:hover').length == 0
				&& $('.id-font4:hover').length == 0
				&& $('.tooltip-container:hover').length == 0
				&& $('.tooltip:hover').length == 0) {

				if( that.find('input[type=checkbox]').is(':checked') ) {
					that.removeClass('selected');
				} else {
					that.addClass('selected');
				}
			}

			setTimeout(function() {

			if($('#line1').is(':checked') || $('#line2').is(':checked')) {
				$('.selected-options').addClass('on');

				if(!$('#line1').is(':checked') || !$('#line2').is(':checked')) {
					$('.single-selected-options').addClass('on');
					$("#all").prop( "checked", false );
					var count = 1;
					$('#all + label').html("Sélectionner ("+count+")");
					$('.options .question').addClass('on');
				} else {
					$('.single-selected-options').removeClass('on');
					$("#all").prop( "checked", true );
					var count = 2;
					$('#all + label').html("Tout décocher ("+count+")");
					$('.options .question').removeClass('on');
				}
			} else {
				$('.single-selected-options').removeClass('on');
				$('.selected-options').removeClass('on');
				$('.options .question').removeClass('on');

				$('#all + label').html("Tout sélectionner");
				$("#all").prop( "checked", false );
			}

			});

	});
	$('.medias-list-container #all').click(function() {
		if( $(this).is(':checked') ) {
			$('.selected-options').addClass('on');
			$("#line1").prop( "checked", true );
			$("#line2").prop( "checked", true );
			$("#line1").closest('tr').addClass('selected');
			$("#line2").closest('tr').addClass('selected');
			$("#all").prop( "checked", true );
			var count = 2;
			$('#all + label').html("Tout décocher ("+count+")");
		} else {
			$('.selected-options').removeClass('on');
			$("#line1").prop( "checked", false );
			$("#line2").prop( "checked", false );
			$("#line1").closest('tr').removeClass('selected');
			$("#line2").closest('tr').removeClass('selected');
			$("#all").prop( "checked", false );
			$('#all + label').html("Tout sélectionner");
		}
		if($('#line1').is(':checked') || $('#line2').is(':checked')) {
			if(!$('#line1').is(':checked') || !$('#line2').is(':checked')) {
				$('.options .question').addClass('on');
			} else {
				$('.options .question').removeClass('on');
			}
		} else {
			$('.options .question').removeClass('on');
		}


	});
	$('.medias-list-container .dropdown-options').click(function() {
		if( $('#type-images').is(':checked') ) {
			$(".table-wrapper table tr.image").show();
			$('.display-label').html("Afficher : Images");
		} else {
			$(".table-wrapper table tr.image").hide();
		}
		if( $('#type-documents').is(':checked') ) {
			$(".table-wrapper table tr.document").show();
			$('.display-label').html("Afficher : Documents");
		} else {
			$(".table-wrapper table tr.document").hide();
		}
		if( $('#type-images').is(':checked') && $('#type-documents').is(':checked')) {
			$('.display-label').html("Afficher : Tout");
		}
		if( !$('#type-images').is(':checked') && !$('#type-documents').is(':checked')) {
			$('.display-label').html("Afficher : Aucun type");
		}
	});

	$('.open-add-media-modal').click(function(e) {
		e.preventDefault();
		$('.add-media-overlay').addClass('active');
		$('body').css({'overflow':'hidden'});
	});
	$('.add-media-overlay .close').click(function(e) {
		e.preventDefault();
		$('.add-media-overlay').removeClass('active');
		$('body').css({'overflow':'initial'});
	});

	$('.open-transfer-modal').click(function(e) {
		e.preventDefault();
		$('.side-modal').addClass('on');
		$('#transfer-certificat .sticky-buttons').fadeIn();
		$('#shadow').fadeIn();
		$('body').css({'overflow':'hidden'});
	});

	// Models
	$('.open-add-model-modal').click(function(e) {
		e.preventDefault();
		var method = $(this).attr('data-method');
		$('.add-model-overlay.'+method+'-upload').addClass('active');
		$('body').css({'overflow':'hidden'});
	});
	$('.add-model-overlay .close').click(function(e) {
		e.preventDefault();
		if($(this).parent().hasClass('submodal')) {
			$(this).closest('.submodal-shadow').fadeOut();
			$(this).closest('.submodal-shadow2').fadeOut();
			$(this).closest('.submodal-shadow3').fadeOut();
		} else {
			$('.add-model-overlay').removeClass('active');
			$('body').css({'overflow':'initial'});
		}
	});
	$('.add-model-overlay .close-like').click(function(e) {
		e.preventDefault();
		$('.add-model-overlay').removeClass('active');
		$('body').css({'overflow':'initial'});
		$('.step').hide();
		$('.step1').show();
	});
	/*$('.add-model-overlay .step1 div').on('click', function() {
		if($(this).hasClass('select-items')) {
			$('.step1 .button-main').removeClass('disabled');
		}
	});*/
	$('.stepfile .upload-wrapper').click(function() {
		if($('.stepfile .button-main').hasClass('ready')) {
			$('.stepfile .button-main').removeClass('disabled');
		}
		setTimeout(function() {
			$('.stepfile .button-main').addClass('ready');
		});
	});
	$('.stepfile .checkbox-wrapper').click(function() {
		if($('.stepfile .button-main').hasClass('ready')) {
			$('.stepfile .button-main').removeClass('disabled');
		}
		setTimeout(function() {
			$('.stepfile .button-main').addClass('ready');
		});
	});

	$('.modal-goto-step1').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step1').fadeIn();
		}
	});
	$('.modal-goto-step2').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step2').fadeIn();
		}
	});
	$('.modal-goto-step3').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step3').fadeIn();
		}
	});
	$('.modal-goto-step4').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step4').fadeIn();
		}
	});
	$('.modal-goto-step5').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step5').fadeIn();
		}
		$('.my-certificat-wrapper .certificat-slider').slick("unslick");
		setTimeout(function() {
			$('.my-certificat-wrapper .certificat-slider').slick({
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 3,
				prevArrow: $('.button-prev'),
				nextArrow: $('.button-next'),
				responsive: [
				{
					breakpoint: 1190,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true,
					}
				}
				]
			});
		},50);
	});


	//

	function checkTransfertCertificat() {
		//if($('#dest-email').val() != "") {
			if($('#client-final').is(':checked')) {
				if($('#dest-email').val() != "") {
					$('#transfer-certificat .confirm').removeClass('disabled');
				}
				$('.more-infos').hide();
			}
			if($('#point-de-vente').is(':checked')) {
				$('.more-infos').show();
				var location = $('#location + .select-selected').html();
				var sold = $('#sold-date').val();
				console.log(location);
				console.log(sold);
				if(location != "Sélectionner un point de vente" && sold != "") {
					if($('#dest-email').val() != "") {
						$('#transfer-certificat .confirm').removeClass('disabled');
					}
				} else {
					$('#transfer-certificat .confirm').addClass('disabled');
				}
			}
		/*} else {
			$('#transfer-certificat .confirm').addClass('disabled');
			//$('#dest-email').addClass('error');
		}*/
	};

	$('#transfer-certificat').click(function() {
		checkTransfertCertificat();
	});
	$('#transfer-certificat input').change(function() {
		checkTransfertCertificat();
	});

	$("#sold-date").datepicker({
		defaultDate: new Date(),
		inline:true,
		showOtherMonths: true,
		altFormat: "dd/mm/yy",
		dateFormat: "dd/mm/yy",
		minDate: 0,
	    onSelect: function(dateText) {
	    	checkTransfertCertificat();
	    }
	});
	$("#sold-date").datepicker("setDate", "-0d");

	/*function sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
      }

	for (var i = 0; i < 9000; i++) {
		console.log("-");
		checkTransfertCertificat();
		sleep(500);
	}*/

	/*$('.tooltip-container').click(function() {
		$(this).toggleClass('on');
	});
	$('body').click(function() {
		if( $('.tooltip-container:hover').length == 0 ) {
			$('.tooltip-container').removeClass('on');
		}
	});$*/

	$('.add-model .open-submodal').click(function() {
		$(this).closest('.step').find('.submodal-shadow').fadeIn();
	});
	$('.add-model .submodal .button-main').click(function() {
		if($(this).hasClass('close-submodal')) {
			$('.submodal-shadow').fadeOut();
		}
	});
	$('.add-model .submodal .close-submodal').click(function() {
		$('.submodal-shadow').fadeOut();
	});
	$('.add-model .submodal .close').click(function() {
		$('.submodal-shadow').fadeOut();
	});
	// Step 1
	//
	$('.add-model .step1-csv .custom-select').click(function() {
		$('.step1-csv').find('.button-main').removeClass('disabled');
	});
	//
	$('.add-model .step1 .submodal .custom-select').click(function() {
		$(this).closest('.submodal').find('.button-main').addClass('close-submodal');
		$(this).closest('.submodal').find('.button-main').removeClass('disabled');
	});
	$('.add-model-manual .step1 .custom-select').click(function() {
		$('.add-model-manual .step1 .button-main').addClass('ok1');
	});
	$('.add-model-manual .step1 input').on('keypress', function (e) {
		if($('#model-name').val() != "") {
			$('.add-model-manual .step1 .modal-goto-step2').addClass('ok2');
		}
		if($('#ref').val() != "") {
			$('.add-model-manual .step1 .modal-goto-step2').addClass('ok3');
		}
		if( $('.add-model-manual .step1 .modal-goto-step2').hasClass('ok1')
			&& $('.add-model-manual .step1 .modal-goto-step2').hasClass('ok2')
			&& $('.add-model-manual .step1 .modal-goto-step2').hasClass('ok3')
		) {
			$('.add-model-manual .step1 .modal-goto-step2').removeClass('disabled');
		}

	});
	/*$('.add-model .step1 .category-dropdown').click(function() {
		$('.add-model .modal-goto-step2').removeClass('disabled');
	});*/
	$('.add-model .step1 .submodal .button-main').click(function() {
		if($(this).hasClass('close-submodal')) {
			var lang = $('.add-model .step1 .submodal .select-selected').html();
			var html = '<div class="wrapper added"><div class="label-bis">'+lang+'</div><input id="model-name" type="text"><div class="id-font4 delete">m</div></div>';
			$('.add-model .step1 .lang-list').append(html);
		}
	});
	$('.add-model .step1 .lang-list').click(function() {
		if($('.delete:hover').length > 0) {
			$('.delete:hover').parent().remove();
		}
	});
	// Step 2
	$('.add-model .step2 .submodal .custom-select').click(function() {
		$(this).closest('.submodal').find('.button-main').addClass('close-submodal');
		$(this).closest('.submodal').find('.button-main').removeClass('disabled');
	});
	$('.add-model .step2 .submodal .button-main').click(function() {
		if($(this).hasClass('close-submodal')) {
			var lang = $('.add-model .step2 .submodal .select-selected').html();
			var html = '<div class="wrapper"><div class="label-bis">'+lang+'</div><textarea id="model-desc" type="text" class="summernote"></textarea><div class="id-font4 delete">m</div></div>';
			$('.add-model .step2 .desc-list').append(html);
			$('.summernote').summernote();
		}
	});
	$('.add-model .step2 .desc-list').click(function() {
		if($('.delete:hover').length > 0) {
			$('.delete:hover').parent().remove();
		}
	});
	$('.add-model .step2 .note-editable').click(function() {
		$('.add-model .step2 .button-main').removeClass('disabled');
	});
	// Step 3
	$('.add-model .step3 .submodal .custom-select').click(function() {
		$(this).closest('.submodal').find('.button-main').addClass('close-submodal');
		$(this).closest('.submodal').find('.button-main').removeClass('disabled');
	});
	$('.add-model .step3 .submodal .button-main').click(function() {
		if($(this).hasClass('close-submodal')) {
			var lang = $('.add-model .step3 .submodal .select-selected').html();
			var html = '<div class="wrapper"><div class="label-bis">'+lang+'</div><textarea id="model-desc" type="text" class="summernote"></textarea><div class="id-font4 delete">m</div></div>';
			$('.add-model .step3 .desc-list').append(html);
			$('.summernote').summernote();
		}
	});
	$('.add-model .step3 .desc-list').click(function() {
		if($('.delete:hover').length > 0) {
			$('.delete:hover').parent().remove();
		}
	});
	$('.add-model .step3 .open-choices').click(function() {
		$('.add-model .step3 .submodal-shadow2').fadeIn();
	});
	$('.add-model .step3 .computer-choice').click(function() {
		$('.add-model .step3 .submodal-shadow2').fadeOut();
		$('.add-model .step3 .big-upload-input').click();
	});
	$('.add-model .step3 .medias-choice').click(function() {
		$('.add-model .step3 .submodal-shadow2').fadeOut();
		$('.add-model .step3 .submodal-shadow3').fadeIn();
	});
	$('.add-model .step3 .submodal-shadow3 .my-medias-list').click(function() {
		$('.add-model .step3 .submodal-shadow3 .button-main').html("Valider (x)");
	});
	$('.add-model .step3 .submodal-shadow3 .button-main').click(function() {
		$('.add-model .step3 .submodal-shadow3').fadeOut();
		var uploadBtn = $(".add-model .step3 .big-upload-input");
		var path = uploadBtn.get(0).value;
		var filename = "R26W1011_03_F.jpg";
		uploadBtn.parent().find(".init").hide();
		uploadBtn.parent().find(".path").show();
		uploadBtn.parent().find(".path").html(filename + ' <span class="id-font4">m</span>');
		uploadBtn.parent().find(".bin").show();
		uploadBtn.parent().find(".photo").show();

		$('.add-model .step3 .button-main').removeClass('disabled');
	});
	$('.add-model .step3 .note-editable').click(function() {
		$('.add-model .step3 .button-main').removeClass('disabled');
	});
	// Step 4
	// Step 4 - Upload 1
	$('.add-model .step4 .open-choices1').click(function() {
		$('.add-model .step4 .submodal-shadow').fadeIn();
	});
	$('.add-model .step4 .submodal-shadow .computer-choice').click(function() {
		$('.add-model .step4 .submodal-shadow').fadeOut();
		$('.add-model .step4 .upload-wrapper1 .big-upload-input').click();
		$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').show();
		$('.add-model .step4 .legend').hide();
	});
	$('.add-model .step4 .submodal-shadow .medias-choice').click(function() {
		$('.add-model .step4 .submodal-shadow').fadeOut();
		$('.add-model .step4 .submodal-shadow3').fadeIn();
	});
	$('.add-model .step4 .submodal-shadow3 .my-medias-list').click(function() {
		$('.add-model .step4 .submodal-shadow3 .button-main').html("Valider (x)");
	});
	$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list div').click(function() {
		if($(this).find('.id-font4:hover').length > 0) {
			$(this).closest('.item').remove();
			var items = $('.add-model .step4 .upload-wrapper1 .uploaded-medias-list > div').length;
			//console.log("items: "+items);
			setTimeout(function() {
				if(items < 1) {
					$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').hide();
					$('.add-model .step4 .reopen-link.open-choices1').hide();
				}
			},50);
		}
	});
	$('.add-model .step4 .submodal-shadow3 .button-main').click(function() {
		$('.add-model .step4 .submodal-shadow3').fadeOut();
		$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').show();
		$('.add-model .step4 .reopen-link.open-choices1').show();
		/*var uploadBtn = $(".add-model .step4 .upload-wrapper1 .big-upload-input");
		var path = uploadBtn.get(0).value;
		var filename = "R26W1011_03_F.jpg";
		uploadBtn.parent().find(".init").hide();
		uploadBtn.parent().find(".path").show();
		uploadBtn.parent().find(".path").html(filename + ' <span class="id-font4">m</span>');
		uploadBtn.parent().find(".bin").show();
		uploadBtn.parent().find(".photo").show();*/
		var html = '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		html += '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		html += '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		//$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').html(html);

		$('.add-model .step4 .button-main').removeClass('disabled');
		$('.add-model .step4 .legend').hide();
	});
	// Step 4 - Upload 2
	/*$('.add-model .step4 .open-choices2').click(function() {
		$('.add-model .step4 .submodal-shadow3').fadeIn();
	});
	$('.add-model .step4 .submodal-shadow3 .computer-choice').click(function() {
		$('.add-model .step4 .submodal-shadow2').fadeOut();
		$('.add-model .step4 .upload-wrapper1 .big-upload-input').click();
		$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').show();
	});
	$('.add-model .step4 .submodal-shadow3 .medias-choice').click(function() {
		$('.add-model .step4 .submodal-shadow1').fadeOut();
		$('.add-model .step4 .submodal-shadow4').fadeIn();
	});
	$('.add-model .step4 .submodal-shadow4 .my-medias-list').click(function() {
		$('.add-model .step4 .submodal-shadow4 .button-main').html("Valider (x)");
	});*/
	$('.add-model .step4 .open-choices2').click(function() {
		$('.add-model .step4 .submodal-shadow2').fadeIn();
	});
	$('.add-model .step4 .submodal-shadow2 .computer-choice').click(function() {
		$('.add-model .step4 .submodal-shadow2').fadeOut();
		$('.add-model .step4 .upload-wrapper2 .big-upload-input').click();
		$('.add-model .step4 .upload-wrapper2 .uploaded-medias-list').show();
		//$('.add-model .step4 .legend').hide();
	});
	$('.add-model .step4 .submodal-shadow2 .medias-choice').click(function() {
		$('.add-model .step4 .submodal-shadow2').fadeOut();
		$('.add-model .step4 .submodal-shadow4').fadeIn();
	});
	$('.add-model .step4 .submodal-shadow4 .my-medias-list').click(function() {
		$('.add-model .step4 .submodal-shadow4 .button-main').html("Valider (x)");
	});
	//
	$('.add-model .step4 .upload-wrapper2 .uploaded-medias-list div').click(function() {
		if($(this).find('.id-font4:hover').length > 0) {
			$(this).closest('.item').remove();
			var items = $('.add-model .step4 .upload-wrapper2 .uploaded-medias-list > div').length;
			console.log("items: "+items);
			setTimeout(function() {
				if(items < 1) {
					$('.add-model .step4 .upload-wrapper2 .uploaded-medias-list').hide();
					$('.add-model .step4 .reopen-link.open-choices2').hide();
				}
			},50);
		}
	});
	//$('.add-model .step4 .submodal-shadow3 .button-main').click(function() {
	$('.add-model .step4 .submodal-shadow3 .upload-wrapper2').click(function() {
		//$('.add-model .step4 .submodal-shadow3').fadeOut();
		$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').show();
		$('.add-model .step4 .reopen-link.open-choices1').show();
		/*var uploadBtn = $(".add-model .step4 .upload-wrapper1 .big-upload-input");
		var path = uploadBtn.get(0).value;
		var filename = "R26W1011_03_F.jpg";
		uploadBtn.parent().find(".init").hide();
		uploadBtn.parent().find(".path").show();
		uploadBtn.parent().find(".path").html(filename + ' <span class="id-font4">m</span>');
		uploadBtn.parent().find(".bin").show();
		uploadBtn.parent().find(".photo").show();*/
		var html = '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		html += '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		html += '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		//$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').html(html);

		$('.add-model .step4 .button-main').removeClass('disabled');
	});
	$('.add-model .step4 .submodal-shadow4 .button-main').click(function() {
		$('.add-model .step4 .submodal-shadow4').fadeOut();
		$('.add-model .step4 .upload-wrapper2 .uploaded-medias-list').show();
		$('.add-model .step4 .reopen-link.open-choices2').show();
		/*var uploadBtn = $(".add-model .step4 .upload-wrapper1 .big-upload-input");
		var path = uploadBtn.get(0).value;
		var filename = "R26W1011_03_F.jpg";
		uploadBtn.parent().find(".init").hide();
		uploadBtn.parent().find(".path").show();
		uploadBtn.parent().find(".path").html(filename + ' <span class="id-font4">m</span>');
		uploadBtn.parent().find(".bin").show();
		uploadBtn.parent().find(".photo").show();*/
		var html = '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		html += '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		html += '<div class="item">';
			html += '<div>';
			    html += '<img src="./static/img/example.jpg" alt="">';
			    html += '<div class="name">R26W1011_03_F.jpg</div>';
			html += '</div>';
			html += '<span class="id-font4">m</span>';
		html += '</div>';
		//$('.add-model .step4 .upload-wrapper1 .uploaded-medias-list').html(html);

		$('.add-model .step4 .button-main').removeClass('disabled');
	});


	$('.open-options').click(function() {
		$(this).find('.options').toggle();
	});

	$('.no-link').click(function(e) {
		e.preventDefault();
	});

	function updatePictos() {
		// Update Pictos
		var step = $('.create-certificat-form-footer').attr("data-step");
		$('.step-item').each(function() {
			var item = $(this);
			//console.log(item.attr('data-step')+" / "+step);
			if(parseInt(item.attr('data-step')) < step) {
				item.removeClass('statut-current');
				item.addClass('statut-check');
			}
			if(step != "1") {
				$('.create-certificat-form-footer').addClass('mini');
			} else {
				$('.create-certificat-form-footer').removeClass('mini');
			}
			if(step == "1" || step == "2") {
				$('.step-item + .submenu').not('.step2-parent-item + .submenu').slideUp();
				$('.step2-parent-item + .submenu').slideDown();
			}
			if(step == "3" || step == "4" || step == "5") {
				$(".statut-lock").each(function() {
					$(this).removeClass("statut-lock");
					$(this).addClass("statut-current");
				});
				$('.step-item + .submenu').not('.step5-parent-item + .submenu').slideUp();
				$('.step5-parent-item + .submenu').slideDown();
				$('.next').removeClass('disabled');
				setTimeout(function() {
					$('.next').removeClass('disabled');
				}, 50);
			}
			if(step == "6" || step == "7") {
				$('.step-item + .submenu').not('.step7-parent-item + .submenu').slideUp();
				$('.step7-parent-item + .submenu').slideDown();
			}
			if(step == "8" || step == "9") {
				$('.step-item + .submenu').not('.step9-parent-item + .submenu').slideUp();
				$('.step9-parent-item + .submenu').slideDown();
				$('.next').removeClass('disabled');
				setTimeout(function() {
					$('.next').removeClass('disabled');
				}, 50);
			}

			if(parseInt(item.attr('data-step')) == step) {
				$('.step-item').removeClass('current');
				$(this).addClass('current');
			}
		});
		// Update Buttons
		$('.button-main.next').addClass('disabled');
	}

	$('.open-confirm-saving').click(function() {
		$('.modal-confirm-saving').fadeIn();
	});
	$('.modal-confirm-saving .close-modal').click(function() {
		$('.modal-confirm-saving').fadeOut();
	});

	$('.create-certificat-form-footer .next').click(function() {
		var futurStep = $(this).parent().attr("data-step"); 
		var currentStep = parseInt($(this).parent().attr("data-step")) + 1; 
		if($('.step'+currentStep).hasClass('step-with-info')) {
			$('#main-footer').addClass('mini');
			$('#main-footer').removeClass('wide');
		} else {
			$('#main-footer').removeClass('mini');
			$('#main-footer').addClass('wide');
		}
		if(futurStep == 8) {
			$('.no-assurance').fadeIn();
		}
		if(futurStep == 9) {
			$('.modal-certificat-validation').addClass('step-version');
			$('.modal-certificat-validation').fadeIn();
			$('.step-item').removeClass('current');
			$('.step10-parent-item').addClass('current');
			$(".side-save").fadeOut();
		} else if(!$(this).hasClass('disabled')) {
			$('.step').fadeOut(200);
			futurStep++;
			setTimeout(function() {
				//console.log('.step'+futurStep);
				$('.step'+futurStep).fadeIn();
				if(futurStep == 3) {
					$('.step2-summernote').summernote();
					$('.step2-summernote').addClass('summernote');
				}
			}, 200);
			$(this).parent().attr("data-step", futurStep);
			updatePictos();
		}
	});
	$('.create-certificat-form-footer .prev').click(function() {
		$('.step').fadeOut(200);
		var futurStep = $(this).parent().attr("data-step");
		futurStep--;
		setTimeout(function() {
			//console.log('.step'+futurStep);
			$('.step'+futurStep).fadeIn();

			var currentStep = futurStep;
			if($('.step'+currentStep).hasClass('step-with-info')) {
				$('#main-footer').addClass('mini');
				$('#main-footer').removeClass('wide');
			} else {
				$('#main-footer').removeClass('mini');
				$('#main-footer').addClass('wide');
			}
		}, 200);
		$(this).parent().attr("data-step", futurStep);
		$('.side-save').fadeIn();
		updatePictos();
	});

	$('.step-item').click(function(e) {
		e.preventDefault();
		var link = $(this).attr('href');
		var futurStep = link.replace('#step', '');
		$('.modal-certificat-validation').fadeOut();

		var currentStep = futurStep;
		if($('.step'+currentStep).hasClass('step-with-info')) {
			$('#main-footer').addClass('mini');
			$('#main-footer').removeClass('wide');
		} else {
			$('#main-footer').removeClass('mini');
			$('#main-footer').addClass('wide');
		}

		//console.log( currentStep ); 
		if(currentStep == "10") {
			$('.side-save').fadeOut();
		} else {
			$('.side-save').fadeIn();
		}


		if(futurStep == 10) {
			$('.modal-certificat-validation').fadeIn();
			$('.modal-certificat-validation').addClass('step-version');
			$('.step-item').removeClass('current');
			$('.step10-parent-item').addClass('current');
			return;
		} else {
			setTimeout(function() {
				$('.modal-certificat-validation').removeClass('step-version');
			}, 400);
		}
		if(!$(this).hasClass('statut-lock')) {
			$('.step').fadeOut(200);
			setTimeout(function() {
				//console.log('.step'+futurStep);
				$('.step'+futurStep).fadeIn();
			}, 200);
			$(this).parent().attr("data-step", futurStep);
			$('.create-certificat-form-footer').attr("data-step", futurStep);
			updatePictos();
		}
	});

	// Enable buttons
	// S1
	$('.create-certificat-form-v1 .step1 .bien').click(function() {
		$('.next').removeClass('disabled');
	});
	$('.watch').click(function() {
		$('.watch-field').show();
		$('.not-watch-field').hide();
	});
	$('.not-watch').click(function() {
		$('.watch-field').hide();
		$('.not-watch-field').show();
	});
	// S2
	$('.create-certificat-form-v1 .step2 input[type="text"]').keypress(function() {
		var empty = 0;
		$('.step2 input[type="text"]').each(function() {
			if($(this).val() == "") {
				empty++;
			}
		});
		if(empty == 0) {
			$('.step2 .preview-link').animate({opacity:1});
			$('.next').removeClass('disabled');
		}
	});
	// S3
	$('.create-certificat-form-v1 .step3 .upload-wrapper').click(function() {
		$('.step3 .preview-link').animate({opacity:1});
	});
	// S6
	$('.create-certificat-form-v1 .step6 .upload-wrapper').click(function() {
		$('.next').removeClass('disabled');
		$('.step6 .preview-link').animate({opacity:1});
	});
	// S7
	$('.create-certificat-form-v1 .step7 .upload-wrapper').click(function() {
		$('.next').removeClass('disabled');
		$('.step7 .preview-link').animate({opacity:1});
	});
	// S8
	$('.create-certificat-form-v1 .step8 input[type="number"]').keypress(function() {
		var empty = 0;
		$('.step8 input[type="number"]').each(function() {
			if($(this).val() == "") {
				empty++;
			}
		});
		if(empty == 0) {
			$('.step8 .preview-link').animate({opacity:1});
			$('.next').removeClass('disabled');
		}
	});
	// S9
	$('.create-certificat-form-v1 .step9 input[type="number"]').keypress(function() {
		var empty = 0;
		$('.step9 input[type="number"]').each(function() {
			if($(this).val() == "") {
				empty++;
			}
		});
		$('.step9 input[type="text"]').each(function() {
			if($(this).val() == "") {
				empty++;
			}
		});
		if(empty == 0) {
			$('.step9 .preview-link').animate({opacity:1});
			$('.next').removeClass('disabled');
		}
	});
	$('.create-certificat-form-v1 .step9 input[type="text"]').keypress(function() {
		var empty = 0;
		$('.step9 input[type="number"]').each(function() {
			if($(this).val() == "") {
				empty++;
			}
		});
		$('.step9 input[type="text"]').each(function() {
			if($(this).val() == "") {
				empty++;
			}
		});
		if(empty == 0) {
			$('.step9 .preview-link').animate({opacity:1});
			$('.next').removeClass('disabled');
		}
	});

	// Digits
	function isNumber (o) {
	  return ! isNaN (o-0);
	}
	$(".digit-2").keyup(function(e){
	 var txtVal = $(this).val();
	 if(isNumber(txtVal) && txtVal.length>2)
	 {
	     $(this).val(txtVal.substring(0,2) )
	 }
	});
	$(".digit-4").keyup(function(e){
			var txtVal = $(this).val();
			if(isNumber(txtVal) && txtVal.length>4)
			{
			    $(this).val(txtVal.substring(0,4) )
			}
			var txtVal = $(this).val();
			if(txtVal>2021) {
				$(this).val("2021");
			}
	});
	$(".digit-6").keyup(function(e){
	 var txtVal = $(this).val();
	 if(isNumber(txtVal) && txtVal.length>6)
	 {
	     $(this).val(txtVal.substring(0,6) )
	 }
	});

	$('.have-to-disappear .no-link').click(function() {
		$('.choose-model').fadeIn();
	});
	$('.prefilled .no-link').click(function() {
		$('.choose-model').fadeIn();
	});
	//$('.info-block.have-to-disappear .no-link').click(function() {
	$('.choose-model .close-submodal').click(function() {
		if( $('#main-footer').attr('data-step') == "2") {
			var that = $('.info-block.have-to-disappear .no-link');
			//$(that).closest('.info-block').hide();
			//$(that).closest('.info-block').next('.have-to-appear').show();
			$('.step2 .have-to-disappear').hide();
			$('.step2 .have-to-appear').show();
			$('#brand-name').val("Courbet");
			$('#ref').val("R26W1011 05");
			$('#french-name').val("Bague Halo C de Coubert 3/4 pavée en or blanc");
			$('#english-name').val("Courbet Halo C ring 3/4 white gold paved");
			$('.next').removeClass('disabled');
		}
	});
	$('.have-to-appear .no-link').click(function() {
		var that = $(this);
		if(that.hasClass('hasToConfirm')) {
			$('.modal-model-confirmation').fadeIn();	
		} else {
			$(this).closest('.step').find('.have-to-disappear').show();
			$(this).closest('.step').find('.have-to-appear').hide();
			$('#brand-name').val("");
			$('#ref').val("");
			$('#french-name').val("");
			$('#english-name').val("");
			$('.next').addClass('disabled');
		}

	});
	/*$('no-link-hasToConfirm').click(function() {
		$(this).closest('.step').find('.have-to-disappear').show();
		$(this).closest('.step').find('.have-to-appear').hide();
		$('#ref').val("");
		$('#french-name').val("");
		$('#english-name').val("");
		$('.next').addClass('disabled');
	});
	$('.no-link.hasToConfirm').click(function(e) {
		$('.modal-model-confirmation').fadeIn();
	});*/ 
	$('.modal-model-confirmation .confirm').click(function() {
		$('.modal-model-confirmation').fadeOut();
		var step = $(this).attr('data-step');
		$('.'+step).find('.have-to-disappear').show();
		$('.'+step).find('.have-to-appear').hide();
		$('#ref').val("");
		$('#french-name').val("");
		$('#english-name').val("");
		$('.next').addClass('disabled');
	});
	$('.modal-model-confirmation .close-submodal').click(function() {
		$('.modal-model-confirmation').fadeOut();
	});

	$('.create-certificat-form-v1 .step3 .big-upload-btn .underline').click(function() {
		$('.single-existing-media').fadeIn();
		$('.single-existing-media').attr('data-step', 'step3');
	});

	$(".create-certificat-form-v1 .big-upload-input" ).change(function( event ) {
		var step = $(this).attr('data-step');
		$('.'+step).find('.upload-wrapper').not(".persist").hide();
		$('.'+step).find('.uploaded-img').addClass("on");
		$('.'+step).find('.to-appear').css({'display':'inline-block'});
		if(step == "step3") {
			$('.modal-image-already-exist').fadeIn();
		}
		/*if(step == "step6") {
			$('.modal-images-already-exist').fadeIn();
		}*/
		if(step == "step6" || step == "step7") {
			$('.'+step).find('.uploaded-img2').addClass("on");
		}
		$('.upload-existing-medias').attr('data-step', step);
	});
	$(".create-certificat-form-v1 .step6 .add-imgs").click(function() {
		//$('.add-imgs-choice').fadeIn();
		// See line 1853 
	});
	$(".create-certificat-form-v1 .step7 .add-imgs").click(function() {
		//$('.add-docs-choice').fadeIn(); 
		// See line 1853 
	});
	$('.modal-image-already-exist .close-submodal').click(function() {
		$('.modal-image-already-exist').fadeOut();
	});
	$('.modal-images-already-exist .close-submodal').click(function() {
		$('.modal-images-already-exist').fadeOut();
	});
	$('.modal-documents-already-exist .close-submodal').click(function() {
		$('.modal-documents-already-exist').fadeOut();
	});
	$(".create-certificat-form-v1 .upload-choosen-img" ).click(function( event ) {
		var step = $(this).attr('data-step');
		$('.'+step).find('.upload-wrapper').not(".persist").hide();
		$('.'+step).find('.uploaded-img').addClass("on");
		$('.'+step).find('.to-appear').css({'display':'inline-block'});
		if(step == "step6" || step == "step7") {
			$('.'+step).find('.uploaded-img2').addClass("on");
		}
		$('.upload-existing-medias').attr('data-step', step);
	});

	$('.upload-existing-medias .close-submodal').click(function() {
		$('.upload-existing-medias').fadeOut();
		var step = $('.upload-existing-medias').attr('data-step');
		$('.'+step).find('.upload-wrapper').not(".persist").hide();
		$('.'+step).find('.uploaded-img').addClass("on");
		$('.'+step).find('.to-appear').css({'display':'inline-block'});
		if(step == "step6" || step == "step7") {
			$('.'+step).find('.uploaded-img2').addClass("on");
			$('.'+step).find('.upload-img').css({'display':'none'});
		}
		if(step == "step7") {
			$('.upload-existed-medias').fadeIn();
		}
	});
	$('.upload-existing-docs .close-submodal').click(function() {
		$('.upload-existing-docs').fadeOut();
		var step = $('.upload-existing-docs').attr('data-step');
		$('.'+step).find('.upload-wrapper').not(".persist").hide();
		$('.'+step).find('.uploaded-img').addClass("on");
		$('.'+step).find('.to-appear').css({'display':'inline-block'});
		if(step == "step6" || step == "step7") {
			$('.'+step).find('.uploaded-img2').addClass("on");
			$('.'+step).find('.upload-img').css({'display':'none'});
		}
		//$('.upload-existed-docs').fadeIn();
	});
	$('.create-certificat-form-v1 .uploaded-img .delete').click(function() {
		$('.modal-delete-img').fadeIn();
	});
	$('.modal-delete-img .close-submodal').click(function() {
		$('.modal-delete-img').fadeOut();
	});
	$('.modal-delete-img .confirm').click(function() {
		var step = $('.upload-existing-medias').attr('data-step');
		$('.'+step).find('.uploaded-img').removeClass("on");
		$('.'+step).find('.upload-wrapper').show();
		$('.modal-delete-img').fadeOut();
	});

	$('.create-certificat-form-v1 .delete-current').click(function() {
		$(this).closest('.img-wrapper').hide();
	});

	$('.create-certificat-form-v1 .step6 .big-upload-btn .underline').click(function() {
		$('.multiple-existing-medias').fadeIn();
		$('.upload-existing-medias').attr('data-step', 'step6');
	});
	$('.create-certificat-form-v1 .step7 .big-upload-btn .underline').click(function() {
		//$('.multiple-existing-docs').fadeIn();
		//$('.upload-existing-docs').attr('data-step', 'step7');
	});

	$('.preview-link').click(function(e) {
		e.preventDefault();
		$('.modal-certificat-preview-big').not('.modal-certificat-validation').fadeIn();
	});

	$('.modal-certificat-preview .close-submodal').click(function() {
		$('.modal-certificat-preview').fadeOut();
	});
	$('.modal-certificat-preview-big .close-submodal').click(function() {
		$('.modal-certificat-preview-big').fadeOut();
	});

	$('.upload-existed-media .close-submodal').click(function() {
		$('.upload-existed-media').fadeOut();
	});
	$('.upload-existed-medias .close-submodal').click(function() {
		$('.upload-existed-medias').fadeOut();
		var step = "step6";
		$('.'+step).find('.upload-wrapper').not(".persist").hide();
		$('.'+step).find('.uploaded-img').addClass("on");
		$('.'+step).find('.to-appear').css({'display':'inline-block'});
		$('.upload-existing-medias').attr('data-step', step);
	});

	$('.upload-existed-doc .close-submodal').click(function() {
		$('.upload-existed-doc').fadeOut();
	});
	$('.upload-existed-docs .close-submodal').click(function() {
		$('.upload-existed-docs').fadeOut();
		var step = "step6";
		$('.'+step).find('.upload-wrapper').not(".persist").hide();
		$('.'+step).find('.uploaded-img').addClass("on");
		$('.'+step).find('.to-appear').css({'display':'inline-block'});
		$('.upload-existing-docs').attr('data-step', step);
	});

	$('.upload-existed-medias .delete').click(function() {
		$(this).parent().remove();
	});
	$('.upload-existed-docs .delete').click(function() {
		$(this).parent().remove();
	});

	$('.open-exited-media').click(function() {
		$('.upload-existed-media').fadeIn();
	});
	$('.open-existed-medias').click(function() {
		$('.upload-existed-medias').fadeIn();
	});

	$('.open-exited-doc').click(function() {
		$('.upload-existed-doc').fadeIn();
	});
	$('.open-existed-docs').click(function() {
		$('.upload-existed-docs').fadeIn();
	});

	//
	$('.see-img').click(function(e) {
		$('.view-img').fadeIn();
	});
	$('.view-img .close').click(function(e) {
		$('.view-img').fadeOut();
	});



	// Step 6
	$('.add-imgs-choice .close-submodal').click(function() {
		$('.add-imgs-choice').fadeOut();
	});
	$('.add-imgs-choice .library').click(function() { console.log('zero');
		$('.multiple-existing-medias').fadeIn();
		var step = $('#main-footer').attr('data-step');
		$('.upload-existing-medias').attr('data-step', step);

		$('.step'+step).find('.upload-wrapper').not(".persist").hide();
		$('.step'+step).find('.uploaded-img').addClass("on");
		$('.step'+step).find('.to-appear').css({'display':'inline-block'});
		$('.step6').find('.upload-img').css({'display':'none'});
	});
	$('.add-imgs-choice .computer').click(function() { 
		var step = $('#main-footer').attr('data-step');
		$('.step6').find('.big-upload-input').click();
	});
	$('.step6 .big-upload-input').change(function( event ) {
		//$('.upload-existed-medias').fadeIn();
		$('.modal-images-already-exist').fadeIn();

		var step = $('#main-footer').attr('data-step');
		$('.step'+step).find('.upload-wrapper').not(".persist").hide();
		$('.step'+step).find('.uploaded-img').addClass("on");
		$('.step'+step).find('.to-appear').css({'display':'inline-block'});
		$('.step'+step).find('.upload-img').css({'display':'none'});
	});

	// Step 7
	$('.add-docs-choice .close-submodal').click(function() {
		$('.add-docs-choice').fadeOut();
	});
	$('.add-imgs').click(function() {
		var step = $(this).attr('data-step');
		$('.'+step).find('to-appear').show();
	})
	//$('.add-docs-choice .library').click(function() {
	$('.add-imgs .library').click(function() {
		var step = $('#main-footer').attr('data-step');
		console.log(step);
		if(step == "3") {
			$('.upload-existing-medias').fadeIn();
		}
		else if(step == "6") {
			$('.multiple-existing-medias').fadeIn();
		}
		else if(step == "7") {
			$('.multiple-existing-docs').fadeIn();
		}
		$('.upload-existing-medias').attr('data-step', step);
		$('.upload-existing-docs').attr('data-step', step);

		$('.step'+step).find('.upload-wrapper').not(".persist").hide();
		$('.step'+step).find('.uploaded-img').addClass("on");
		$('.step'+step).find('.to-appear').css({'display':'inline-block'});
		$('.step'+step).find('.upload-img').css({'display':'none'});
	});
	//$('.add-docs-choice .computer').click(function() {
	$('.add-imgs .computer').click(function() {
		var step = $('#main-footer').attr('data-step');
		$('.step'+step).find('.big-upload-input').click();
	});
	$('.step7 .big-upload-input').change(function( event ) {
		//$('.upload-existed-docs').fadeIn();
		$('.modal-documents-already-exist').fadeIn();

		var step = $('#main-footer').attr('data-step');
		$('.step'+step).find('.upload-wrapper').not(".persist").hide();
		$('.step'+step).find('.uploaded-img').addClass("on");
		$('.step'+step).find('.to-appear').css({'display':'inline-block'});
		$('.step'+step).find('.upload-img').css({'display':'none'});
	});

	$('.upload-doc2 .custom-select').click(function() {
		$('.upload-doc2 .doc-wrapper').css({'z-index':'0'});
		$(this).css({'z-index':'1'});
		$(this).closest('.doc-wrapper').css({'z-index':'1'});
	});
	$('.upload-doc2 .select-selected').click(function() {
		$('.upload-doc2 .doc-wrapper').css({'z-index':'0'});
		$(this).closest('.doc-wrapper').css({'z-index':'1'});
	});

	$('.doc-wrapper .delete').click(function() {
		$(this).closest('.doc-wrapper').hide();
	});

	//
	$('.no-assurance .close-submodal').click(function() {
		$('.no-assurance').fadeOut();
	});

	$('.media-item label').click(function() {
		$(this).parent().toggleClass('selected');
		if( $(this).parent().find('input').attr('type') == "radio" ) {
			$('label').not(this).parent('.media-item').removeClass('selected');
		}
	});

	$('.modal-documents-already-exist input[type="checkbox"]').change(function() {
		var count = 0;
		$('.modal-documents-already-exist input[type="checkbox"]').each(function() {
			if( $(this).is(':checked') ) {
				count = parseInt(count) + 1;
			}
		});
		if(count == 0) {
			$('.modal-documents-already-exist .confirm').html("Ignorer");
		} else if (count == 1) {
			$('.modal-documents-already-exist .confirm').html("Utiliser le document de la médiathèque");
		} else {
			$('.modal-documents-already-exist .confirm').html("Utiliser les "+count+" documents de la médiathèque");
		}
	});
	$('.modal-images-already-exist input[type="checkbox"]').change(function() {
		var count = 0;
		$('.modal-images-already-exist input[type="checkbox"]').each(function() {
			if( $(this).is(':checked') ) {
				count = parseInt(count) + 1;
			}
		});
		if(count == 0) {
			$('.modal-images-already-exist .confirm').html("Ignorer");
		} else if (count == 1) {
			$('.modal-images-already-exist .confirm').html("Utiliser l'image de la médiathèque");
		} else {
			$('.modal-images-already-exist .confirm').html("Utiliser les "+count+" images de la médiathèque");
		}
	});
	$('.my-medias-list input[type="checkbox"]').change(function() {
		var count = 0;
		$(this).closest('.my-medias-list').find('input[type="checkbox"]').each(function() {
			if( $(this).is(':checked') ) {
				count = parseInt(count) + 1;
			}
		});
		if(count == 0) {
			count = "";
		}
		console.log(count);
		$(this).closest('.submodal').find('.count').html(count);
	});
});




